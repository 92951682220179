import React from "react"
import { Link, graphql } from "gatsby"

import Moment from "react-moment"
import ReactMarkdown from "react-markdown"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"

import { faTag, faPencilAlt } from "@fortawesome/free-solid-svg-icons"
library.add( faTag, faPencilAlt )

export const query = graphql`
  query PostQuery($id: Int!) {
    strapiPost(strapiId: { eq: $id }) {
      strapiId
      slug
      title
      content
      published
      updated
      show_card_image_in_post
      card_image_description
      card_image {
        publicURL
      }
      categories {
        slug
        name
      }
    }
  }
`

const Post = ({ data }) => {
  const post = data.strapiPost
  const categories = post.categories
  return (
    <Layout title={post.title}>
      <div className="uk-section">
        <div id="title" className="uk-container uk-container-small">
          <h1 className="uk-align-center">{post.title}</h1>
          <div id="publishing-info">
            <div>
              <span id="publish-date">
                <strong><Icon icon="pencil-alt" size="xs"/> <Moment format="MMM Do, YYYY">{post.published}</Moment></strong>
              </span>
              {
                post.updated !== null && 
                <>
                <span> | </span>
                <span id="update-date">
                  <i>Updated: <Moment format="MMM Do YYYY, h:mm a">{post.updated}</Moment></i>
                </span>
                </>
              }
            </div>
            <div id="post-categories">
              {categories.map(category => (
                <Link to={`/category/${category.slug}`} title={category.name}>
                    <Icon icon="tag" size="xs"/>{category.name}
                </Link>
                )
              )}
            </div>
            
          </div>
        </div>
        <div className="uk-container uk-container-small">
          <hr />
        </div>
        {
          post.show_card_image_in_post === true &&
          <div className="uk-container uk-container-small">
            <div id="banner">
              <img
                id="banner-image"
                data-src={post.card_image?.publicURL}
                data-srcset={post.card_image?.publicURL}
                alt={post.card_image_description}
                title={post.card_image_description}
                data-uk-img
                data-width
                data-height
                uk-img
              />
            </div>
          </div>
          
        }
        <div className="uk-section">
          <div className="uk-container uk-container-small">
            <ReactMarkdown source={post.content} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Post